@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/lapsus-pro');

@font-face {
  font-family: 'MyCustomFont';
  src: url('./assets/fonts/RetroBrushArabicPersonalUseOnly-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #655870;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: black;
  border-radius: 10px;
}
html {
  background-color: black;
  font-family: 'MyCustomFont', sans-serif;
  scrollbar-color: #655870 black; 

}

.font-input {
  font-family: 'Open Sans', sans-serif;
}

.heartbeat {
	animation: beat 0.7s infinite alternate;
}

@keyframes beat {
	to { transform: scale(1.1); }
}



/* firefox */


@keyframes slideInBounce {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  60% {
    transform: translateY(10%);
    opacity: 1;
  }
  80% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutBounce {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  20% {
    transform: translateY(-5%);
  }
  40% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.navbar-slide-in {
  animation: slideInBounce 0.7s ease-out forwards;
}

.navbar-slide-out {
  animation: slideOutBounce 0.7s ease-in forwards;
}

.text-custom-brown {
  color: #301d09;
}